.fondoPoliticas {
  background: rgb(25, 37, 50);
  min-height: 100vh;
}

.archivo {
  background-color: rgb(25, 37, 50);
  color: white;
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  .contenedor-archivo {
    width: 95%;
  }
}

.topImagen {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5vw 0;
  background: rgb(61, 67, 92);
}
.urlImagen {
  text-align: center;
}
.topImagen img {
  width: 10%;
}

.topPoliticas {
  display: flex;
  padding: 0.5vw 3vw;
  color: white;
  font-size: 1.7vw;
  font-weight: bold;
  background: rgb(80, 180, 162);
  text-shadow: 0.5px 0.5px white;
}

.fondoPoliticas p,
ol,
ul {
  padding: 0 2.5vw;
  color: white;
  font-size: 1.3vw;
}

@media only screen and (max-width: 768px) {
  .archivo {
    .documento {
      margin-top: 1em;
      margin-bottom: 1em;
      width: 98%;
    }
  }

  .fondoPoliticas p {
    padding: 4vw 5vw;
    font-size: 4vw;
  }

  .topImagen img {
    width: 40%;
    margin: 3vw auto;
  }

  .topPoliticas {
    font-size: 5vw;
    padding-top: 2vw;
    padding-bottom: 2vw;
    justify-content: center;
  }
}
