.contenedor-reporte {
    display: flex;
    flex-direction: column;
    width: 50vw;

    .contenido {
        padding: 2em 3em;

        .fila-info-reportado {
            display: flex;
            margin: 0 1em;
            align-items: center;

            .imagenPerfil {
                width: 5vw;
                height: 5vw;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                overflow: hidden;
                margin-right: 1.2em;
                background-color: #3a455a;

                > img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }

            .columna-info {
                display: flex;
                flex-direction: column;
                justify-content: center;

                > * {
                    margin: 0;
                    color: rgb(87, 87, 87);
                }
            }

            .btn-cerrar {
                margin-left: auto;
            }
        }

        .line {
            width: 100%;
            height: 2px;
            background: #c6c5c4;
            border: none;
        }

        .columna-reporte {
            display: flex;
            flex-direction: column;
            margin: 1em;

            >* {
                margin: 0.2em 0;
                color: rgb(87, 87, 87);
            }
        }

        .fila-botones {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            .btn-desactivar{
                width: 50%;
                border: none;
            }
        }
    }
}