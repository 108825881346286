.loginContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 2vw 0;
}

.loginContainer img {
    width: 15%;
    margin: 1vw;
}

.ancho4 {
    width: 50%;
}

.bandaVerde {
    width: 100%;
    background-color: #50b4a2;
    color: white;
    display: flex;
    font-size: 2vw;
    justify-content: space-between;
    align-items: center;
    padding: 1vw 0;
    font-weight: bold;
}

.bandaVerde img {
    width: 10%;
    margin: 0 0 0 2%;
}

.bandaVerde button {
    width: 13%;
    margin: 0 2% 0 0;
    color: white;
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    font-size: 1.5vw;
}

.olvideContraseña {
    background: none;
    color: white;
    margin: 1vw;
    font-size: 1.4vw;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    outline: none;
}

.btnLogin {
    cursor: pointer;
}

/* Adminitrador */

.conetendorAdmin {
    background-color: white;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
}

.conetendorFunciones {
    width: 350%;
    height: 100%;
    display: flex;
    margin-left: -150vw;
    transition: 1s all ease;
    overflow-y: auto;

    .columna-chats {
        display: flex;
        flex-direction: column;

        .fila-titulo {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btn-reportes {
                border: 0;
                height: 2em;
                outline: none;
                font-size: 1.2em;
                margin-left: 1em;
            }

            .num-solicitudes {
                margin-right: 1.5em;
            }
        }
    }

    @import "./reporte.scss";
}

.newClubContainer {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.datosClubContainer {
    width: 85%;
    padding: 2vw;
    border-radius: 1.5vw;
    background-color: rgb(23, 37, 49);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2vw;
}

.datosFotoCLub {
    display: flex;
    flex-direction: column;
    width: 20%;
    align-items: center;
}

.datosFotoCLub label {
    width: 14vw;
    color: white;
    background-color: rgb(80, 180, 162);
    padding: 0.3vw;
    text-align: center;
    font-weight: bold;
    font-size: 1.3vw;
    border-radius: 0.3vw;
    margin: 1vw auto;
}

.textoImagenClub {
    color: white;
    text-align: center;
    width: 70%;
    font-size: 1.15vw;
}

.datosFotoCLub label > input {
    display: none;
}

.fotoNuevoCLub {
    width: 10vw;
    height: 10vw;
    background-color: white;
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
}

.fotoNuevoCLub img {
    max-width: 85%;
    max-height: 85%;
}

.camposClub {
    display: flex;
    flex-direction: column;
    margin: 0 5%;
    width: 30%;
    padding: 0 2vw;
    align-items: center;
    justify-content: center;
}

.botonAgregarClub {
    align-self: flex-end;
    margin-left: 2vw;
}

.bandaAgregarCLub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 88%;
    color: rgb(58, 69, 90);
    font-size: 2vw;
    height: 6vw;
    font-weight: bold;
}
.vacio {
    width: 3vw;
    height: 3vw;
}

.clubsContainer {
    width: 50vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
}

.bandaAzul {
    display: flex;
    width: 88%;
    background-color: rgb(58, 69, 90);
    color: white;
    font-size: 2vw;
    height: 6vw;
    align-items: center;
    padding: 0 1vw;
    font-weight: bold;
}

.botonCircular {
    background-color: rgb(80, 180, 162);
    color: white;
    font-size: 2.5vw;
    border-radius: 100%;
    width: 3vw;
    height: 3vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    cursor: pointer;
}
.botonCircular:hover {
    text-shadow: 1px 1px black;
    font-weight: bold;
}

.vistaClubs {
    width: 88%;
    height: 83%;
    padding: 0 1vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(198, 197, 196);
}
.vistaClubs input {
    width: 80%;
    padding: 0.7vw 5%;
    font-size: 1.4vw;
    background-color: white;
    border: none;
    border-radius: 0.5vw;
    margin: 1vw 0;
    outline: none;
}
.vistaClubs input::placeholder {
    color: rgb(198, 197, 196);
}

.tituloGris {
    font-size: 2.2vw;
    color: rgb(90, 90, 90);
    margin: 1vw auto;
}

.listaClubs {
    width: 90%;
    margin: 0 1vw;
    overflow-y: auto;
    overflow-x: hidden;
    height: 90%;
    padding-bottom: 1vw;
}

.club {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 3.3vw;
    padding: 0 1.5vw;
    color: rgb(100, 100, 100);
    font-size: 2vw;
    border-radius: 0.5vw;
}
.club:hover {
    background-color: rgb(80, 180, 162);
}

.club span {
    width: 85%;
    margin: 0.5vw 0;
}

.imagenCLub {
    width: 3vw;
    height: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.club img {
    max-width: 100%;
    max-height: 100%;
}

.bandaChats {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88%;
    color: rgb(58, 69, 90);
    font-size: 2vw;
    height: 6vw;
    font-weight: bold;
}

.chat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4vw;
    padding: 0.5vw 1vw;
    color: white;
    font-size: 1.1vw;
    cursor: pointer;
}

.fondo-azul {
    background-color: rgb(58, 69, 90);
}

.fondo-gris {
    background-color: rgb(198, 197, 196);
    color: rgb(46, 46, 46);
}

.fondo-blanco {
    background-color: white;
}
.textosChat {
    width: 70%;
}

.textosChat div {
    width: 98%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.fechaMensaje {
    font-size: 0.8vw;
    align-self: flex-end;
    width: 18%;
}

.chatImagenContainer {
    width: 3.8vw;
    height: 3.8vw;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.chat img {
    max-width: 100%;
    max-height: 100%;
}

.chatContainer {
    width: 50vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.informacionChat {
    width: 88%;
    padding: 0.5vw 0;
    display: flex;
    margin: 1vw;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 1px rgb(198, 197, 196);
}

.chatMensajes {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 88%;
    height: 75%;
}

.chatImagen {
    width: 4vw;
    height: 4vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 1vw;
}

.chatImagen img {
    max-width: 100%;
    max-height: 100%;
}

.textosInfoChat {
    width: 65%;
    padding: 0.5vw 1%;
}

.iconosChat {
    width: 10%;
    display: flex;
    font-size: 1.6vw;
    justify-content: flex-end;
    align-items: center;
    margin-right: 2%;
    color: rgb(80, 180, 162);
}

.iconosChat nav {
    margin-left: 0.8vw;
    cursor: pointer;
    transition: 0.5s all ease;
}

.iconosChat nav:hover {
    color: rgb(23, 37, 49);
}

.enviarMensaje {
    width: 96%;
    background-color: rgb(198, 197, 196);
    padding: 1vw 2%;
    display: flex;
    justify-content: space-between;
}

.enviarMensaje textarea {
    width: 88%;
    background-color: white;
    padding: 1vw;
    font-size: 1.5vw;
    color: rgb(100, 100, 100);
    border-radius: 0.5vw;
    border: none;
    outline: none;
    max-height: 10vw;
    resize: none;
    font-family: Arial, Helvetica, sans-serif;
}

.enviarMensaje input::placeholder {
    color: rgb(170, 170, 170);
}

.enviarMensaje button {
    width: 10%;
    background: none;
    border: none;
    font-size: 3vw;
    cursor: pointer;
    outline: none;
    color: rgb(80, 180, 162);
    align-self: flex-end;
}

.mensajesContainer {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    overflow: auto;
}

.mensaje {
    width: auto;
    max-width: 75%;
    margin: 0.4vw 1vw;
    display: flex;
    flex-direction: column;
}
.mensaje > span {
    border-radius: 0.5vw;
    padding: 0.5vw;
    font-size: 1.2vw;
    font-weight: normal;
}

.mensajeCliente > span {
    background-color: rgb(80, 180, 162);
    color: white;
}

.mensajeCliente {
    align-self: flex-end;
}
.mensajeSoporte {
    align-self: flex-start;
}

.mensajeSoporte > span {
    background-color: rgb(198, 197, 196);
    color: rgb(46, 46, 46);
}

.mensaje > .fechaMensaje {
    margin: 0.2vw 0.5vw 0;
    color: rgb(100, 100, 100);
    font-weight: bold;
    width: 95%;
}

.mensajeSoporte > .fechaMensaje {
    text-align: start;
    align-self: flex-end;
}

.mensajeCliente > .fechaMensaje {
    text-align: end;
    align-self: flex-start;
}

@media only screen and (max-width: 768px) {
    .loginContainer {
        padding: 5vw 0;
    }

    .loginContainer img {
        width: 40%;
    }

    .ancho4 {
        width: 80%;
    }

    .bandaVerde {
        font-size: 5vw;
        padding: 3vw 0;
        margin: 4vw 0 2vw;
    }

    .olvideContraseña {
        margin: 2vw;
        font-size: 4vw;
    }
}
