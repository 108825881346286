.fondoContacto {
    width: 100%;
    min-height: 100vh;
    background: url(../assets/fondo.jpg) fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(0,0,0,.75);
    background-position: 50%;
    display: flex;
    flex-wrap: wrap;
}

.contenedorOpcionesContacto {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    height: 7vw;
}

.opcionTopContacto {
    color: #fff;
    text-decoration: none;
    padding: 2vw;
    font-size: 1.3vw;
    width: 13%;
}

.opcionTopContacto img {
    width: 100%;
}

.contenedorFormulario {
    width: 80%;
    margin-left: 10%;
    margin-bottom: 3vw;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-self: flex-start;
}

.contenedorCampo {
    display: flex;
    flex-direction: column;
    margin-bottom: 2vw;
}

.ancho3 {
    width: 31%;
}

.contenedorCampo select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    display: inline-block;
    width: 100%;
    cursor: pointer;
    position: relative;
}
.contenedorCampo input, .contenedorCampo select {
    background: #cecece;
    border-radius: 1.2vw;
    padding: .5vw 1vw;
    font-size: 1.5vw;
    color: #000;
    border: none;
    outline: none;
}

.contenedorCampo span {
    color: #50b4a2;
    text-shadow: 0.4px 0.4px #50b4a2;
}
.contenedorSelect {
    position: relative;
}
.contenedorSelect img {
    position: absolute;
    height: 64%;
    z-index: 1;
    top: 20%;
    right: 3%;
}
.contenedorCampo div {
    color: #fff;
    font-size: 1.6vw;
    margin-left: 1.5vw;
    margin-bottom: 1vw;
    text-shadow: 0.4px 0.4px #fff;
}
.ancho2 {
    width: 47%;
}
.ancho1 {
    width: 100%;
}

.contenedorCampo textarea {
    background: #cecece;
    border-radius: 2vw;
    padding: 1vw;
    font-size: 1.5vw;
    color: #000;
    border: none;
    outline: none;
    height: 12vw;
    resize: none;
}
.muchoPadding {
    border: none;
    padding: .3vw 2vw .5vw;
    font-size: 2vw;
    outline: none;
}

@media only screen and (max-width: 768px) {
    .fondoContacto {
        background-size: auto 125%;
        background-position-y: 80%;
    }
    
    .contenedorMuchoPadding {
        align-self: flex-end;
        width: 50%;
        margin: 2vw auto;
    }

    .contenedorOpcionesContacto {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60vw;
        margin-top: 8vh;
    }

    .opcionTopContacto {
        width: 30%;
    }

    .ancho1, .ancho2, .ancho3, .opcionTopContacto img {
        width: 100%;
    }
    .contenedorCampo div {
        font-size: 5vw;
    }
    .contenedorCampo input, .contenedorCampo select {
        border-radius: 5vw;
        padding: 1vw;
        font-size: 5vw;
    }
    .contenedorCampo textarea {
        border-radius: 4vw;
        padding: 1vw;
        font-size: 3.5vw;
        height: 30vw;
    }
    .muchoPadding {
        border: none;
        padding: 1vw 5vw;
        font-size: 5vw;
        outline: none;
        width: 100%;
    }
}