.fondo{
    width: 100%;
    padding-bottom: 25vw;
    background: url(../assets/fondo.jpg) fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
}

.contenedorOpciones{
    display: flex;
    justify-content: flex-end;
}

.opcionTop{
    color: white;
    text-decoration: none;
    padding: 2vw;
    font-size: 1.3vw;
}

.informacionApp{
    width: 37%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
}

.informacionApp img{
    width: 80%;
}

.informacionApp p{
    color: white;
    font-size: 2vw;
}

.botonVerde{
    color: white;
    background: rgb(80, 180, 162);
    padding: 0.2vw 0.5vw 0.4vw;
    border-radius: 0.5vw;
    font-weight: bold;
    text-shadow: 0.5px 0.5px white;
    font-size: 1.6vw;
    text-decoration: none;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    min-width: 5vw;
    text-align: center;
    cursor: pointer
}

.pocoPadding{
    padding: 0.2vw 0.5vw;
    font-size: 1.2vw;
    display: block;
}

.imagenesTiendas {
    margin-top: 2vw;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.imagenesTiendas img{
    width: 45%;
}



.fondo2{
    width: 100%;
    padding-bottom: 6vw;
    position: relative;
}

.contenedorUsuarios{
    width: 100%;
    margin-top: -10vw;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.tituloFondoVerde{
    color: white;
    width: 11%;
    text-align: center;
    background: rgb(25, 37, 50);
    padding: 0.7vw 2vw;
    border-radius: 0.5vw;
    font-weight: bold;
    text-shadow: 0.5px 0.5px white;
    font-size: 2.5vw;
    text-decoration: none;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
    box-shadow: 1px 1px 5px 0px rgba(0,0,0,0.75);
}

.perfilesContainer{
    display: flex;
    justify-content: center;
    margin-top: 2vw;
}

.perfil{
    width: 20%;
    background: rgb(58, 68, 93);
    display: flex;
    margin: 0 0.6%;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding-bottom: 1vw;
}

.imagenPerfilContainer{
    max-width: 40%;
    height: 10vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.imagenPerfilContainer > img{
    width: 100%;
}

.perfil p{
    width: 85%;
    color: white;
    padding: 0.5vw 3%;
    font-size: 0.85vw;
    background: rgb(74, 84, 114);
    margin-top: 1vw;
    height: 9.5vw;
}
.tituloPerfil{
    margin: 1vw auto;
    color: white;
    font-size: 2vw;
    font-weight: bold;
    text-shadow: 0.5px 0.5px white;
}

.botonPerfil{
    position: absolute;
    bottom: -0.5vw;
    margin: auto;
}

.botonPlan{
    position: absolute;
    bottom: -0.5vw;
    margin: auto;
}

.fondoGris{
    padding-top: 3.5vw;
    background-color: rgb(198, 197, 197);
    padding-bottom: 0;
}

.ContenedorPlanesUsuarios{
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.subtituloVerde{
    color: rgb(25, 37, 50);
    margin: 1vw;
    font-size: 2.5vw;
    font-weight: bold;
    text-shadow: 0.5px 0.5px rgb(25, 37, 50);
}

.absoluto{
    position: absolute;
    top: -2vw;
    margin-left: 42.5%;
}

.contenedorPlanes{
    width: 100%;
    background: rgb(25, 37, 50);
    margin-top: 0.5vw;
    display: flex;
    justify-content: center;
    padding: 1.5vw 0;
}
.cld{
    width: 18%;
    margin: 0.5%;
}
.plan{
    width: 100%;
    background: rgb(218, 220, 222);
    padding: 0.5vw 0 3vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    border-radius: 0.4vw;
}

.tituloPlan{
    background: rgb(80, 180, 162);
    color: white;
    font-size: 1.5vw;
    padding: 0.1vw 0;
    width: 100%;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1vw;
}

.imagenPlanContainer{
    width: 85%;
    height: 10vw;
    display: flex;
    justify-content: center;
}

.imagenPlanContainer > img{
    max-width: 100%;
    max-height: 100%;
}

.caracteristicasPlan{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 8%;
    margin-top: 1vw;
}

.caracteristica{
    margin-top: 0.5vw;
    color: black;
    font-size: 0.9vw;
    display: flex;
}

.caracteristica span{
    width: 95%;
}

.caracteristica .check{
    background: rgb(25, 37, 50);
    color: rgb(80, 180, 162);
    font-size: 0.75vw;
    margin-right: 0.2vw;
    width: 1.2vw;
    height: 1.2vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
}

.unPlan{
    width: 49%;
    display: inline-flex;
}

.marginRigth{
    margin-right: 2%;
}
.planMasGrande{
    width: 45%;
}

.planMasGrande .plan{
    height: 17vw;
}

.imagenPlanGrande{
    height: 14vw;
}

.foot{
    background: rgb(74, 88, 114);
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 1vw;
}

.redesContainer{
    width: 50%;
    display: flex;
    justify-content: flex-end;
}

.emailContainer{
    width: 50%;
    display: flex;
    align-items: center;
    font-size: 1.5vw;
}

.emailContainer a{
    text-decoration: none;
    color: white;
}

.mensaje{
    width: 7%;
    margin-right: 1vw;
    margin-left: 2vw;
    font-weight: bold;
}

.mensaje img{
    width: 100%;
}


.redes{
    width: 7%;
    margin-right: 2vw;
}

.redes img{
    width: 100%;
}

.botonesPrivacidad{
    display: flex;
    justify-content: space-between;
}

.btnPoliticas{
    width: 49%;
    border-left: solid 1px rgb(198, 197, 197);
    border-right: solid 1px rgb(198, 197, 197);
    display: flex;
    color: black;
    background: white;
    padding: 0.8vw;
    justify-content: center;
    text-decoration: none;
}

.movilContainer{
    display: none;
}

.emailContainer {
    width: 50%;
    display: flex;
    align-items: center;
    font-size: 1.5vw;
}

.mensajeImg {
    width: 7%;
    margin-right: 1vw;
    margin-left: 2vw;
    font-weight: 700;
}
.mensajeImg img {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .contenedorOpciones{
        display: none;
    }

    .fondo{
        background: url(../assets/fondo.jpg);
        background-attachment: inherit;
        background-size: 350% auto;
        background-position-x: center;
        background-position-y: 50%;
        justify-content: center;
        align-items: center;
        padding-top: 20vw;
    }
    
    .informacionApp{
        width: 75%;
        margin-left: 0; 
        justify-content: center;
        align-items: center;
    }

    .informacionApp img{
        width: 35%;
    }

    .informacionApp p{
        color: white;
        font-size: 4vw;
    }

    .informacionApp p span{
        text-shadow: 0.8px 0.8px white;
    }

    .botonVerde{
        font-size: 6vw;
        padding: 1vw;
        border-radius: 1vw;
        cursor: pointer
    }

    .botonTop{
        text-align: center;
        margin-top: 10vw;
        margin-bottom: 2vw;
        margin-right: 3vw;
    }

    .imagenesTiendas {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .imagenesTiendas img{
        width: 100%;
        margin-top: 3vw;
    }



    .fondo2{
        padding-bottom: 6vw;
        padding-bottom: 20vw;
    }

    .contenedorUsuarios{
        margin-top: 3vw;
    }

    .tituloFondoVerde{
        width: 40%;
        font-size: 7vw;
    }

    .perfilesContainer{
        flex-wrap: wrap;
    }

    .perfil{
        width: 100%;
        margin: 3vw 0;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 5% 3vw;
    }

    .imagenPerfilContainer{
        width: 40%;
    }

    .imagenPerfilContainer > img{
        width: 100%;
    }

    .perfil p{
        width: 45%;
        font-size: 3vw;
        height: auto;
        padding: 3vw;
        margin-top: 2vw;
    }
    .tituloPerfil{
        width: 100%;
        font-size: 7vw;
        text-align: center;
        margin: 2vw 0;
    }

    .botonPerfil{
        position: absolute;
        top: 2vw;
        right: 5vw;
        width: 25%;
    }

    .fondoGris{
        padding-top: 0;
        background-color: rgb(198, 197, 197);
        padding-bottom: 0;
    }

    .absoluto{
        position: absolute;
        top: -15vw;
        left: -12%;
    }

    .subtituloVerde{
        margin: 1vw;
        font-size: 5vw;
    }

    .contenedorPlanes{
        background: none;
        flex-wrap: wrap;
    }
    .cld{
        width: 48%;
        margin: 0.5%;
        display: flex;
    }
    .plan{
        background: white;
        padding: 1vw 0 3vw;
        border-radius: 1vw;
        margin-bottom: 6vw;
    }

    .tituloPlan{
        font-size: 4vw;
        padding: 0.5vw 0;
    }

    .imagenPlanContainer{
        width: 90%;
        height: 25vw;
    }

    .caracteristicasPlan{
        display: flex;
        flex-direction: column;
        width: 90%;
        margin-left: 8%;
        margin-top: 1vw;
    }

    .caracteristica{
        margin-top: 0.5vw;
        font-size: 2vw;
    }

    .caracteristica span{
        width: 95%;
    }

    .caracteristica .check{
        font-size: 2vw;
        margin-right: 0.5vw;
        width: 2.5vw;
        height: 2.5vw;
    }

    .botonPlan{
        bottom: -3vw;
    }

    .botonPlan .botonVerde{
        font-size: 3.5vw;
        cursor: pointer
    }

    .unPlan{
        width: 80%;
        display: flex;
    }

    .marginRigth{
        margin-right: 10%;
    }
    .planMasGrande{
        width: 80%;
    }

    .planMasGrande > .plan .caracteristicasPlan .caracteristica{
        font-size: 3vw;
        margin-top: 1vw;
    }

    .planMasGrande .plan{
        height: 50vw;
    }

    .imagenPlanGrande{
        height: 40vw;
    }

    .foot{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2vw;
    }

    .movilContainer{
        display: flex;
        width: 95%;
        justify-content: space-between;
    }

    .footLink{
        color: white;
        font-size: 2.5vw;
        text-decoration: none;
    }

    .redesContainer{
        width: 80%;
        justify-content: center;
        padding: 3vw 0 0;
    }

    .emailContainer{
        display: none;
    }

    .mensaje img{
        width: 100%;
    }


    .redes{
        width: 20%;
        margin-right: 2vw;
    }

    .redes img{
        width: 100%;
    }
    .botonesPrivacidad{
        flex-direction: column;
    }
    .btnPoliticas{
        width: 98%;
        border-right: none;
        border-left: none;
        border-bottom: solid 1px rgb(198, 197, 197);
        border-top: solid 1px rgb(198, 197, 197);
    }
    .movilContainer{

    }

}