.contenedor-permiso
    .texto2
        font-size: 1.8vw     

.fondo-permiso
    background-position: bottom
    height: 100vh
    height: auto
    padding-bottom: 0
    min-height: 100vh

    @media screen and ( max-width: 800px )
        padding: 0
        justify-content: flex-start   
    
.formulario-permiso
    font-size: 1vw
    height: auto
    

    h2
        margin: 2em 0 0.5em
        font-size: 2em
        color: white
        text-shadow: 1px 1px 10px black

    .ancho4
        width: 40vw

        div
            text-shadow: 1px 1px 5px black

    .botonVerde
        padding: 0.2em 1em
        font-size: 1.7em
        cursor: pointer

    .nombre-jugador
        font-size: 2.2vw
        font-weight: bold
        text-shadow: 1px 1px 10px black

    @media screen and ( max-width: 800px )
        font-size: 2.5vw

        .contenedorCampo
            width: 95%
            text-align: center
            margin-bottom: 2em
            margin-top: 2em

            div
                font-size: 2.5em
    
            input
                margin-top: 0.7em
                height: 2em
                width: 90%
                padding: 0.2em 5%

        .contenedorMuchoPadding
            font-size: 2em
            width: 80%
        
        .nombre-jugador
            margin-top: 0.5em
            font-size: 2em
        