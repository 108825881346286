.dialogo {
    position: fixed;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    background: hsla(0,0%,45.9%,.6);
    display: flex;
    align-items: center;
}

.cerrarDialogo{
    width: 100vw;
    height: 100vh;
    position: absolute;
}


.fondoDialogo img{
    width: 12%;
}

.texto1{
    color: white;
    font-size: 4vw;
    text-align: center;
    width: 80%;
    font-weight: bold;
}

.texto2{
    color: white;
    font-size: 3vw;
    text-align: center;
    width: 80%;
    font-weight: bold;
}

.bd{
    margin-bottom: 3vw;
    cursor: pointer;
}

.lds-default {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #fff;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}

.loginSeccion{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.fondoDialogo{
  background-image: url(../assets/ic_fondo.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 65%;
  height: 85vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 5vw;
  z-index: 1000;

  @media (max-width: 800px) {
    width: 95%;
    height: auto;
    min-height: 50vh;
    padding: 5vh 0;

    img{
      width: 30%;
    }

    .texto1{
      font-size: 1.3em;
    }

    .texto2{
      font-size: 1em !important;
    }

  }
}

.loginSeccion .lds-default div{
  background-color: rgb(80, 180, 162);
}

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
  
